.Msg-list {
    height: 84%;
    overflow:scroll;
}
.Msg-wrapper {
    display:flex;
    justify-content: space-between;
    align-items:center;
    width:100%;
    min-height:9%;
    height:fit-content;
    /* max-height:25%; */
    /* overflow:scroll; */
    border: solid 1px rgb(224, 224, 224);
    margin-bottom: 2px;
    font-size:24px;
}

.Msg-time {
    margin-left:2%;
}
.Msg-type, .Msg-question, .Msg-resolved  {
    padding:2px;
}
.Msg-author {
    font-weight:bold;
}
/* .Msg-question {

} */
.Msg-content {
    height:100%;
    /* overflow:scroll; */
}


.Msg-item {
    text-align: center;
    /* overflow:scroll; */
}

.Msg-nb {
    border:solid 2px rgba(0, 0, 0, 0.54);
    border-radius: 2px;
    width:fit-content;
    margin: auto;
    padding: 2px 6px;
}
.Msg-Resolved {
    background-color: lightgray;
    font-weight: normal;
}
.Msg-unresolved {
    font-weight: bold;
    background-color: white;
}
.Msg-regular {
    font-weight: normal;
    background-color: white;
}
.Msg-ByHost {
    background-color:lightskyblue;
}