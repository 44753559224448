.App-logo {
  bottom:0px;
  pointer-events: none;
}

.App {
  min-height:100%;
  width:100%;
  /* background-color: rgb(41, 41, 41); */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}
.App-name {
  font-size:35px;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background-color: rgb(41, 41, 41);
  color: rgb(0, 153, 255);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
