.Welcome-button, .Copy-button {
    margin: 20px;
}

.App-header {
    text-align:center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    background-color: white;
    color: rgb(0, 153, 255);
}
.Warning, .MeetingId {
    min-height: 3vh;
}
.MeetingId {
    font-weight: bold !important;
}

.Copy-button {
    padding: 6px 6px !important;
    min-width: 0 !important;
}

.Id-textarea {
    border:none;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    max-height: 1.5rem;
    max-width: 5rem;
    outline: none;
    resize: none;
    text-align: center;
}
.CopyId {
    display:flex;
    align-items:center;
    margin:auto;
    justify-content: space-around;
    width:fit-content;
}

.back-button {
    position:absolute !important;
    top:3%;
    left:3%;
}