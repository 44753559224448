.Chat-wrapper {
    height:100%;
    width:100%;
}

.Chat-header {
    display:flex;
    justify-content: space-between;
    align-items:center;
    width:100%;
    height:8%;
    border-bottom: solid 1px rgb(233, 233, 233);
}
.Chat-info {
    margin-left:2%;
}

.Chat-name {
    font-weight:bold;
    font-size: 15px;
}

.Chat-AppName {
    text-align:center;
    display: flex;
    font-size: 28px;
    color: rgb(0, 153, 255);
}

.Chat-end {
    margin-right:2%;
}

.Chat-input {
    width: 100%;
    min-height:8%;
    position:fixed;
    bottom:0;
}
.Chat-input > * {
    border-top: 1px solid gray;
    
}
.Send-buttons {
    min-height:100%;
    display:flex;
}
.Icon {
    height:100%;
    margin:auto !important;
    width:100%;
}
.Filter {
    height:100%;
    display:flex;
    align-items: center;
}
.Filter-button {
    margin:auto !important;
    height:100%;
    width:100%; 
    font-size: 15px;
}
.Text-field {
    width:100%
}

.Current-filter {
    font-weight:bold;
    margin:auto;
    font-size:12px;
}

.Filter-wrap {
    text-align:center;
    height:100%;
    display:flex;
    align-items:center;
}

.height {
    height: 100%;
}
.height > div {
    height:100% !important;
}

.Header-item {
    text-align: center;
    font-size:10px;
}